<script>
export default {
  name: 'LandingMobileNavbarHamburger',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<template>
  <div
    class="relative cursor-pointer"
    @click="toggleMenu"
  >
    <div class="relative h-4 w-10">
      <div
        class="absolute h-0.5 w-7 rounded-sm bg-white transition ease-in-out"
        :class="isMenuOpen ? 'top-1.5 rotate-45' : 'top-0'"
      />
      <div
        class="absolute top-1.5 h-0.5 w-7 rounded-sm bg-white transition ease-in-out"
        :class="{ '-rotate-45': isMenuOpen }"
      />
      <div
        class="absolute top-3 h-0.5 w-5 rounded-sm bg-white transition ease-in-out"
        :class="{ 'invisible rotate-45': isMenuOpen }"
      />
    </div>
    <div
      v-if="isMenuOpen"
      class="absolute right-3 top-10 z-30 size-6 origin-center rotate-45 rounded border-4 border-gray-700 bg-pv-blue-900"
    />
    <div
      v-if="isMenuOpen"
      class="absolute right-3.5 top-10 z-40 size-5 origin-center rotate-45 rounded bg-pv-blue-900"
      :class="{ 'invisible': !isMenuOpen }"
    />
    <div
      v-if="isMenuOpen"
      class="absolute -right-3 top-12 z-30 flex w-64 flex-col overflow-hidden rounded-lg border border-gray-700 bg-pv-blue-900 pt-4 text-base shadow-xl sm:w-80 sm:text-lg"
    >
      <slot />
    </div>
  </div>
</template>

<style>

</style>
